var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-submission-form" },
    [
      _c(
        "FormRoot",
        {
          attrs: {
            "form-id": "showcase-submission",
            "enable-submit": !_vm.submitting,
            "submit-button-text": `${
              _vm.submitting ? "Submitting..." : "Share your work"
            }`,
          },
        },
        [
          _c("h2", { staticClass: "form-category" }, [
            _vm._v("\n      Your Information\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-info" },
            [
              _c(
                "div",
                { staticClass: "responsive-margin-right" },
                [
                  _c("TextInput", {
                    ref: "firstName",
                    attrs: {
                      model: _vm.firstName,
                      label: "First Name",
                      required: "true",
                      validate: "true",
                      "hide-asterisk": true,
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.firstName = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("TextInput", {
                ref: "lastName",
                attrs: {
                  model: _vm.lastName,
                  label: "Last Name",
                  required: "true",
                  validate: "true",
                  "hide-asterisk": true,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.lastName = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "responsive-flex" },
            [
              _c("TextInput", {
                ref: "email",
                attrs: {
                  model: _vm.email,
                  label: "Email Address",
                  required: "true",
                  validate: "true",
                  "hide-asterisk": true,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.email = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("h2", { staticClass: "form-category" }, [
            _vm._v("\n      Project Details\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "title-input" },
            [
              _c("TextInput", {
                ref: "title",
                attrs: {
                  model: _vm.title,
                  label: "Project Title",
                  required: "true",
                  validate: "true",
                  placeholder: "Tell us the project name",
                  maxlength: _vm.maxTitleChars,
                  "hide-asterisk": true,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.title = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "spacer-30" }),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "description",
            attrs: {
              placeholder:
                "Tell us about the goals of the project as it relates to the organizations overall priorities and objectives.",
              model: _vm.description,
              label: "Project objective (recommended 1,500 character maximum)*",
              required: "true",
              validate: "true",
              maxlength: _vm.maxDescriptionChars,
              rows: 10,
            },
            on: {
              "update:model": function ($event) {
                _vm.description = $event
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }),
          _vm._v(" "),
          _c("div", { staticClass: "spacer-30" }),
          _vm._v(" "),
          _c("SelectInput", {
            ref: "brand",
            attrs: {
              name: "brand",
              label: "Brand/Sub-brand",
              "custom-classes": "your-office-input",
              list: _vm.brandSelectList,
              model: _vm.brand,
              required: "true",
              validate: "true",
              "hide-asterisk": true,
            },
            on: {
              "update:model": function ($event) {
                _vm.brand = $event
              },
              "change-event": _vm.changeBrand,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "responsive-3-columns" },
            [
              _c("SelectInput", {
                ref: "country",
                attrs: {
                  name: "country",
                  label: "Country",
                  "custom-classes": "your-office-input",
                  list: _vm.countrySelectList,
                  model: _vm.country,
                  required: "true",
                  validate: "true",
                  "hide-asterisk": true,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.country = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("SelectInput", {
                ref: "region",
                attrs: {
                  name: "region",
                  label: "Region",
                  "custom-classes": "your-office-input",
                  list: _vm.regionSelectList,
                  model: _vm.region,
                  required: "true",
                  validate: "true",
                  "hide-asterisk": true,
                },
                on: {
                  "update:model": function ($event) {
                    _vm.region = $event
                  },
                  "change-event": _vm.changeRegion,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "spacer-30" }),
          _vm._v(" "),
          _c("h3", { staticClass: "form-category" }, [
            _vm._v("\n      Audience\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "responsive-3-columns" },
            [
              _c("CheckboxArrayInput", {
                ref: "audience",
                attrs: {
                  name: "audience",
                  model: _vm.audience,
                  list: _vm.audienceSelectList,
                  required: "true",
                  validate: "true",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.audience = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "spacer-30" }),
          _vm._v(" "),
          _c("h3", { staticClass: "form-category" }, [
            _vm._v("\n      Communication channels\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "responsive-3-columns" },
            [
              _c("CheckboxArrayInput", {
                ref: "communication",
                attrs: {
                  name: "communication",
                  model: _vm.communication,
                  list: _vm.communicationSelectList,
                  required: "true",
                  validate: "true",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.communication = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "spacer-30" }),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "inspiration",
            attrs: {
              name: "inspiration",
              label: "Audience insight or inspiration for design*",
              placeholder:
                "Tell us about the audience or industry insight(s) that inspired this creative work.",
              model: _vm.inspiration,
              required: "true",
              validate: "true",
              maxlength: _vm.maxInspirationChars,
              rows: 4,
            },
            on: {
              "update:model": function ($event) {
                _vm.inspiration = $event
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }),
          _vm._v(" "),
          _c("div", { staticClass: "spacer-30" }),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "results",
            attrs: {
              name: "results",
              label: "Results or verbatims*",
              placeholder:
                "Tell us about this project's measurable accomplishments or results.",
              model: _vm.results,
              required: "true",
              validate: "true",
              maxlength: _vm.maxResultsChars,
              rows: 4,
            },
            on: {
              "update:model": function ($event) {
                _vm.results = $event
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }),
          _vm._v(" "),
          _c("div", { staticClass: "spacer-30" }),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "team",
            attrs: {
              name: "team",
              label: "Team members*",
              placeholder:
                "Provide the names of all team members involved in the project.",
              model: _vm.team,
              required: "true",
              validate: "true",
              maxlength: _vm.maxTeamChars,
              rows: 4,
            },
            on: {
              "update:model": function ($event) {
                _vm.team = $event
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }),
          _vm._v(" "),
          _c("div", { staticClass: "spacer-30" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-input upload-input" },
            [
              _c(
                "label",
                { staticClass: "active", attrs: { for: "XG7p2r0gn" } },
                [_vm._v("Upload related images (up to 10)")]
              ),
              _vm._v(" "),
              _c("showcase-file-upload", {
                ref: "fileUpload",
                attrs: {
                  "upload-button-text": "Add File(s)",
                  "upload-files-demo": "uploadFilesDemo",
                },
                on: { "send-hash-collection": _vm.onHashCollectionReady },
              }),
              _vm._v(" "),
              _c("showcase-links", {
                ref: "showcaseLinks",
                attrs: {
                  links: _vm.links,
                  placeholder_text: "e.g. Policies",
                  placeholder_url: "e.g. http://www.site.com/link",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }