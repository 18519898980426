var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subpage-layout submission" }, [
    _c(
      "div",
      { staticClass: "showcase-sidebar" },
      [
        _c("back-button", {
          attrs: {
            "showcase-id": _vm.showcaseId,
            "button-text": "Back to " + (_vm.showcase && _vm.showcase.name),
          },
        }),
        _vm._v(" "),
        _c("info"),
      ],
      1
    ),
    _vm._v(" "),
    _vm.showcase
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "flex-desktop" },
              [
                _c("h1", { staticClass: "title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.submission && _vm.submission.title) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _vm.isPublished
                  ? _c("ShowcaseNavigationButtons", { staticClass: "top" })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isPublished
              ? _c("div", { staticClass: "showcase-actions" }, [
                  _c(
                    "div",
                    {
                      staticClass: "action-item like",
                      on: { click: _vm.toggleLike },
                    },
                    [
                      !_vm.isLiked
                        ? _c("icons", { attrs: { name: "showcase-favorite" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLiked
                        ? _c("icons", {
                            class: { fill: _vm.isLiked },
                            attrs: { name: "showcase-favorite-fill" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isLiked ? _c("span", [_vm._v("Like")]) : _vm._e(),
                      _vm._v(" "),
                      _vm.isLiked ? _c("span", [_vm._v("Unlike")]) : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "action-item follow",
                      on: { click: _vm.toggleFollow },
                    },
                    [
                      _c("icons", {
                        class: { fill: _vm.isFollowed },
                        attrs: { name: "showcase-notifications" },
                      }),
                      _vm._v(" "),
                      !_vm.isFollowed
                        ? _c("span", [_vm._v("Follow")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isFollowed
                        ? _c("span", [_vm._v("Unfollow")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "action-item share" }, [
                    _c(
                      "a",
                      { attrs: { href: _vm.mailToLink } },
                      [
                        _c("icons", { attrs: { name: "showcase-mail" } }),
                        _vm._v("\n          Share\n        "),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "spacer-36" }),
            _vm._v(" "),
            _vm.sliderData
              ? _c(
                  "div",
                  [
                    _c("ClientShowcaseSlider", {
                      staticClass: "mb-lg",
                      attrs: {
                        id: "showcase_submission_slider",
                        title: false,
                        slides: _vm.sliderData,
                        "slideshow-delay": "7000",
                        "show-controls": _vm.sliderData.length > 1,
                        "show-indicators": _vm.sliderData.length > 1,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "spacer-10" }),
            _vm._v(" "),
            _vm.submission
              ? _c("div", { staticClass: "toggle" }, [
                  _c("p", { staticClass: "headline" }, [
                    _vm._v("\n        Objective:\n      "),
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.submission.description,
                        expression: "submission.description",
                      },
                    ],
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "spacer-50" }),
            _vm._v(" "),
            _vm.submission
              ? _c("div", { staticClass: "toggle" }, [
                  _c("p", { staticClass: "headline" }, [
                    _vm._v(
                      "\n        Audience insight or inspiration:\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.inspiration,
                        expression: "inspiration",
                      },
                    ],
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "spacer-50" }),
            _vm._v(" "),
            _vm.submission
              ? _c("div", { staticClass: "toggle" }, [
                  _c("p", { staticClass: "headline" }, [
                    _vm._v("\n        Results or verbatims:\n      "),
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.results,
                        expression: "results",
                      },
                    ],
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "spacer-50" }),
            _vm._v(" "),
            _c("info", { attrs: { classes: "show-mobile info-wrapper" } }),
            _vm._v(" "),
            _c("div", { staticClass: "showcase-comments" }, [
              _c("h1", { staticClass: "comments-title" }, [
                _vm._v(
                  "\n        Comments (" +
                    _vm._s(_vm.comments.length) +
                    ")\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onCommentSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.comment,
                        expression: "comment",
                      },
                    ],
                    attrs: { placeholder: "Type your comment here…" },
                    domProps: { value: _vm.comment },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.comment = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "spacer-10" }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary link-button mt-3",
                      attrs: { type: "submit", title: "Submit Content" },
                    },
                    [_vm._v("\n          Submit Comment\n        ")]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "spacer-70" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "comment-section" },
              _vm._l(_vm.comments, function (item, index) {
                return _c("div", { key: index, staticClass: "comment" }, [
                  _c("div", { staticClass: "meta" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(item.commenter) + " | " + _vm._s(item.date)
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._isAdminUser
                      ? _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.editComment(item)
                              },
                            },
                          },
                          [_vm._v("Edit")]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(item.message))]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "submission-navigation" },
              [
                _vm.isPublished
                  ? _c("ShowcaseNavigationButtons", {
                      staticClass: "bottom",
                      on: { navClick: _vm.onNavClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }