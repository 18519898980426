var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "client-submission-info", class: _vm.classes },
    [
      _vm.authorName
        ? _c("div", [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Author:\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "info-content" }, [
              _vm._v("\n      " + _vm._s(_vm.authorName) + "\n    "),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.brand
        ? _c("div", [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Brand/Sub-brand:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.brand,
                  expression: "brand",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.region
        ? _c("div", [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Region:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.region,
                  expression: "region",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.audience
        ? _c("div", [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Audience:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.audience,
                  expression: "audience",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.communication
        ? _c("div", [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Communication channels:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.communication,
                  expression: "communication",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.team
        ? _c("div", [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Team members:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.team,
                  expression: "team",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.type
        ? _c("div", [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Project Type:\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.type,
                  expression: "type",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.links.length > 0
        ? _c("div", [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Related Links:\n    "),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "links" },
              _vm._l(_vm.links, function (link, index) {
                return _c("li", { key: index }, [
                  _c("a", { attrs: { href: link.url, target: "_blank" } }, [
                    _vm._v(_vm._s(link.text)),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }