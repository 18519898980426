var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-intro" },
    [
      _vm.showcase
        ? _c("h1", { staticStyle: { display: "none" } }, [
            _vm._v("\n    " + _vm._s(_vm.showcase.name) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h2", { staticClass: "title" }, [
        _vm._v("\n    Put your hard work in the showcase\n  "),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    This is an opportunity to be inspired by the amazing creativity across enterprise teams. And that means yours, too. So, don’t hold back. Let’s see your brilliant art or copy that’s elevating the Optum brand to new heights. Ready? Good.\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "btn primary link-button",
          attrs: {
            to: _vm.$generateRoute({
              name: "showcase.submit",
              params: {
                showcaseId: _vm.showcaseId,
              },
            }),
          },
        },
        [_vm._v("\n    Start now\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }