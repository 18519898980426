var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormRoot",
    {
      staticClass: "admin-showcase-submission-form",
      attrs: {
        "form-id": "showcase-submission",
        "enable-submit": true,
        "show-save-button": false,
      },
    },
    [
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    Your Information\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "responsive-flex" },
        [
          _c(
            "div",
            {
              staticClass: "responsive-margin-right",
              staticStyle: { flex: "1" },
            },
            [
              _c("TextInput", {
                ref: "firstName",
                staticStyle: { flex: "1" },
                attrs: {
                  model: _vm.firstName,
                  label: "First Name",
                  required: "true",
                  validate: "true",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.firstName = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("TextInput", {
            ref: "lastName",
            staticStyle: { flex: "1" },
            attrs: {
              model: _vm.lastName,
              label: "Last Name",
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.lastName = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "responsive-flex",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "responsive-margin-right",
              staticStyle: { flex: "1" },
            },
            [
              _c("TextInput", {
                ref: "email",
                staticClass: "responsive-margin-right",
                staticStyle: { flex: "1" },
                attrs: {
                  model: _vm.email,
                  label: "Email Address",
                  required: "true",
                  validate: "true",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.email = $event
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    Project Information\n  "),
      ]),
      _vm._v(" "),
      _c("TextInput", {
        ref: "title",
        attrs: {
          label: "Project Title",
          model: _vm.title,
          required: "true",
          validate: "true",
          maxlength: _vm.maxTitleChars,
        },
        on: {
          "update:model": function ($event) {
            _vm.title = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c("small", [_vm._v(_vm._s(_vm.titleCharsLeft) + " characters left")]),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category small" }, [
        _vm._v(
          "\n    Project objective (recommended 1,500 character maximum)\n  "
        ),
      ]),
      _vm._v(" "),
      _c("TextareaInput", {
        ref: "description",
        attrs: {
          placeholder: "Project Description...",
          model: _vm.description,
          required: "true",
          validate: "true",
          maxlength: _vm.maxDescriptionChars,
          rows: 10,
        },
        on: {
          "update:model": function ($event) {
            _vm.description = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c("small", [
          _vm._v(_vm._s(_vm.descriptionCharsLeft) + " characters left"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "-20px", flex: "1" } },
        [
          _c("SelectInput", {
            ref: "brand",
            staticStyle: { flex: "1" },
            attrs: {
              label: "Brand/Sub-brand",
              list: _vm.brandSelectList,
              model: _vm.brand,
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.brand = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "responsive-flex" }, [
        _c(
          "div",
          {
            staticClass: "responsive-margin-right",
            staticStyle: { "margin-top": "-20px", flex: "1" },
          },
          [
            _c("SelectInput", {
              ref: "country",
              staticStyle: { flex: "1" },
              attrs: {
                label: "Country",
                list: _vm.countrySelectList,
                model: _vm.country,
                required: "true",
                validate: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.country = $event
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "margin-top": "-20px", flex: "1" } },
          [
            _c("SelectInput", {
              ref: "region",
              staticStyle: { flex: "1" },
              attrs: {
                label: "Region",
                list: _vm.regionSelectList,
                model: _vm.region,
                required: "true",
                validate: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.region = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    Audience\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "responsive-3-columns" },
        [
          _c("CheckboxArrayInput", {
            ref: "audience",
            attrs: {
              name: "audience",
              model: _vm.audience,
              list: _vm.audienceSelectList,
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.audience = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category" }, [
        _vm._v("\n    Communication channels\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "responsive-3-columns" },
        [
          _c("CheckboxArrayInput", {
            ref: "communication",
            attrs: {
              name: "communication",
              model: _vm.communication,
              list: _vm.communicationSelectList,
              required: "true",
              validate: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.communication = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category small" }, [
        _vm._v('\n    Audience insight or inspiration for design"\n  '),
      ]),
      _vm._v(" "),
      _c("TextareaInput", {
        ref: "inspiration",
        attrs: {
          placeholder:
            "Tell us about the audience or industry insight(s) that inspired this creative work.",
          model: _vm.inspiration,
          required: "true",
          validate: "true",
          maxlength: _vm.maxInspirationChars,
          rows: 10,
        },
        on: {
          "update:model": function ($event) {
            _vm.inspiration = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c("small", [
          _vm._v(_vm._s(_vm.inspirationCharsLeft) + " characters left"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category small" }, [
        _vm._v("\n    Results or verbatims\n  "),
      ]),
      _vm._v(" "),
      _c("TextareaInput", {
        ref: "results",
        attrs: {
          placeholder:
            "Tell us about this project's measurable accomplishments or results.",
          model: _vm.results,
          required: "true",
          validate: "true",
          maxlength: _vm.maxResultsChars,
          rows: 10,
        },
        on: {
          "update:model": function ($event) {
            _vm.results = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c("small", [
          _vm._v(_vm._s(_vm.resultsCharsLeft) + " characters left"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category small" }, [
        _vm._v("\n    Team Members\n  "),
      ]),
      _vm._v(" "),
      _c("TextareaInput", {
        ref: "team",
        attrs: {
          placeholder:
            "Provide the names of all team members involved in the project.",
          model: _vm.team,
          required: "true",
          validate: "true",
          maxlength: _vm.maxTeamChars,
          rows: 10,
        },
        on: {
          "update:model": function ($event) {
            _vm.team = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c("small", [_vm._v(_vm._s(_vm.teamCharsLeft) + " characters left")]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category small" }, [
        _vm._v("\n    Upload 1-10 images (Optional):\n  "),
      ]),
      _vm._v(" "),
      _c("p", { staticStyle: { "font-size": "14px", color: "#111" } }, [
        _vm._v(
          "\n    Photographs should be less than 5MB in .jpg or .png format, and ideally\n    4:3 ratio. At least one image is required.\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.fileHashCollection && _vm.fileHashCollection.length
        ? _c("showcase-file-upload", {
            ref: "files",
            attrs: {
              "upload-button-text": "Add File(s)",
              "upload-files-demo": "uploadFilesDemo",
              "initial-file-hash-collection": _vm.fileHashCollection,
            },
            on: { "send-hash-collection": _vm.onHashCollectionReady },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm._v(" "),
      _c("h2", { staticClass: "form-category small" }, [
        _vm._v("\n    Include any relevant links (Optional):\n  "),
      ]),
      _vm._v(" "),
      _c("showcase-links", {
        ref: "showcaseLinks",
        attrs: {
          links: _vm.links,
          placeholder_text: "e.g. Policies",
          placeholder_url: "e.g. http://www.site.com/link",
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticStyle: { height: "1px", background: "#e0e0e0", margin: "10px 0" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }